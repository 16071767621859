import styled from '@emotion/styled';
import Link from 'next/link';
import { Box, BoxProps, Button, Heading, Text, VisuallyHidden, useTokens } from '@mezzoforte/forge';
import { X } from '@mezzoforte/forge-icons';
import { HuutokaupatImage as Image } from '@/components/Image/HuutokaupatImage';
import { FocusableBox } from '@/components/Box/FocusableBox';
import { Favorite } from '@/types/Favorite';
import { TimeLeft, TimeLeftDescription } from '@/components/Entry/TimeLeft';
import { useFavorites } from '@/hooks/useFavorites';
import { useHermesEntry } from '@/hooks/useHermesEntry';
import { formatCurrency } from '@/util/currency';
import { isUsersBidSurpassed, isUsersBidWinning } from '@/util/bid';

const { shadows } = useTokens.huutokaupat;

const Wrapper = FocusableBox<BoxProps>(Box);

const FavoriteLink = styled(Link)`
  :focus {
    box-shadow: none;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
`;

interface FavoriteCardProps {
  readonly bidderId: number | null;
}

export function FavoriteCard({
  photoUrl,
  title,
  id,
  slug,
  auctionEnd,
  vatPerc,
  totalPrice,
  biddingEnded,
  bidCount,
  continuousAuction,
  bidderId,
}: Favorite & FavoriteCardProps) {
  const { hermesEntry } = useHermesEntry(id, vatPerc);
  const { removeFavorite } = useFavorites();

  const highestBid = hermesEntry && hermesEntry.bids?.length > 0 ? hermesEntry.bids[0].amount : totalPrice;
  const hasWinningBid = hermesEntry && isUsersBidWinning(hermesEntry.bids, bidderId);
  const hasSurpassedBid = hermesEntry && isUsersBidSurpassed(hermesEntry.bids, bidderId);
  const isCancelled = hermesEntry?.isCancelled ?? false;
  return (
    <Wrapper
      as="article"
      borderRadius={8}
      boxShadow={shadows.box}
      my={3}
      overflow="hidden"
      position="relative"
      width={130}
      data-test={`favorites-list-card-${id}`}
    >
      <Box
        height={70}
        position="relative"
      >
        <Image
          src={photoUrl}
          alt=""
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box padding={2}>
        <FavoriteLink
          href={`/kohde/${id}/${slug}`}
          title={title}
        >
          <VisuallyHidden>
            <Heading variant="h3">{title}</Heading>
          </VisuallyHidden>
        </FavoriteLink>
        <Text
          fontWeight={600}
          fontSize={14}
          lineHeight={1}
        >
          {formatCurrency(highestBid.amount)}
        </Text>
        {!isCancelled && hasWinningBid && (
          <Text
            color="success"
            fontSize={12}
            lineHeight={1.2}
          >
            Tarjouksesi on tällä hetkellä voittava!
          </Text>
        )}
        {!isCancelled && hasSurpassedBid && (
          <Text
            color="danger"
            fontSize={12}
          >
            Tarjouksesi ylitettiin
          </Text>
        )}
        <Text fontSize={12}>
          <TimeLeftDescription
            entryId={id}
            isEnded={hermesEntry?.isEnded ?? biddingEnded}
            isCancelled={isCancelled}
            auctionEnd={new Date(hermesEntry?.auctionEnd ?? auctionEnd)}
            isContinuousAuction={hermesEntry?.isContinuous ?? continuousAuction}
            bidCount={hermesEntry?.bidCount ?? bidCount}
            ignoreOngoing
          />
          <TimeLeft
            endsAt={new Date(hermesEntry?.auctionEnd ?? auctionEnd)}
            id={id}
          />
        </Text>
      </Box>
      <Button
        icon={<X size={16} />}
        aria-label="Poista seurantalistalta"
        title="Poista seurantalistalta"
        onClick={() => removeFavorite.mutate(id)}
        backgroundColor="white"
        position="absolute"
        top="5px"
        right="5px"
        zIndex="docked"
        height={6}
        width={6}
        minH={0}
        minW={0}
      />
    </Wrapper>
  );
}
