import { SliceZone } from '@prismicio/react';
import { Grid } from '@mezzoforte/forge';
import { fetchPrismicSettings } from '@/prismic/prismicSettings';
import NavContainer from '@/features/SiteHeader/NavContainer';
import { components as sliceComponents } from '../../../slices';

export async function MainNavigation() {
  const prismicSettings = await fetchPrismicSettings();

  const slices = prismicSettings?.data.slices2;

  return (
    <NavContainer>
      <Grid
        autoFlow="column"
        as="ul"
        gap={{ base: 2, lg: 4 }}
        m={0}
        px={{ base: 2, lg: 0 }}
        py={{ base: '10px', lg: 0 }}
      >
        <SliceZone
          slices={slices}
          components={sliceComponents}
        />
      </Grid>
    </NavContainer>
  );
}
