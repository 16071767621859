export function VideolyPagePlaceholder({ title }: { title: string }) {
  return (
    <>
      <div
        className="videoly-page-placeholder"
        id="videoly-videobox-placeholder"
      />
      <div
        id="videoly-page-title"
        style={{ display: 'none' }}
      >
        {title}
      </div>
    </>
  );
}
