import Image from 'next/image';
import { Img } from '@chakra-ui/react';
import Link from 'next/link';
import { Box, Flex } from '@mezzoforte/forge';
import { fetchPrismicSettings } from '@/prismic/prismicSettings';
import { Container } from '@/components/Container/Container';
import { MainNavigation } from '@/features/SiteHeader/MainNavigation';
import { HeaderTextBanners } from '@/features/SiteHeader/HeaderTextBanners';
import { HeaderSearchForm } from '@/features/SiteHeader/HeaderSearchForm';
import HidingStickyContainer from '@/features/SiteHeader/HidingStickyContainer';
import logo from 'public/images/huutokaupat-full-white.svg';

export const NAV_HEIGHT = 74;

export async function SiteHeader() {
  const prismicSettings = await fetchPrismicSettings();

  return (
    <>
      <HeaderTextBanners data-test="header-text-banners" />
      <HidingStickyContainer
        background="brandSecondary"
        as="header"
        zIndex={20}
        width="100%"
        color="textInvert"
        height={NAV_HEIGHT}
        topOffset={37}
        className="no-print"
      >
        <Container>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            py={{ base: 2, sm: 3 }}
            position="relative"
          >
            <Box
              as={Link}
              href="/"
              display="flex"
              height={{ base: 'auto', lg: '40px' }}
            >
              <Img
                as={Image}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                src={logo}
                alt="Huutokaupat.com"
                height={{ base: '32px', sm: '40px' }}
                width={{ base: '133px', sm: '222px' }}
              />
            </Box>
            <Flex
              alignItems="center"
              style={{ gap: '1.5rem' }}
            >
              <MainNavigation />
              <HeaderSearchForm placeholder={prismicSettings.data.search_placeholder ?? ''} />
            </Flex>
          </Flex>
        </Container>
      </HidingStickyContainer>
    </>
  );
}
